export default function detectScrollbarWidth(): number {
  // thanks to https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement("div")
  scrollDiv.setAttribute(
    "style",
    `width: 100px; height: 100px;
    overflow: scroll; position:absolute; top:-9999px;`
  )
  document.body.appendChild(scrollDiv)
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)
  return scrollbarWidth
}
